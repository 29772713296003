class Accordion {
	constructor(accordionElement) {
		this.animatingItems = new Set();
		this.accordion = accordionElement;
		this.items = Array.from(
			this.accordion.querySelectorAll('.wp-block-proactive-accordion-item')
		);
		this.toggle = this.accordion.getAttribute('data-toggle') === 'true';

		this.initializeEventListeners();
		this.initializeFirstState();
	}

	initializeEventListeners() {
		// Use event delegation to handle click and keyboard events
		this.accordion.addEventListener('click', (event) => {
			const header = event.target.closest(
				'.wp-block-proactive-accordion-item__header'
			);
			if (header) {
				const item = header.closest('.wp-block-proactive-accordion-item');
				this.toggleItem(item);
			}
		});

		this.accordion.addEventListener('keydown', (event) => {
			this.handleKeyboardNavigation(event);
		});
	}

	toggleItem(item) {
		const header = item.querySelector(
			'.wp-block-proactive-accordion-item__header'
		);
		const content = item.querySelector(
			'.wp-block-proactive-accordion-item__content'
		);

		if (!header || !content) return;

		const isExpanded = item.getAttribute('aria-expanded') === 'true';

		if (isExpanded) {
			this.closeItem(item, header, content);
		} else {
			this.openItem(item, header, content);
		}
	}

	openItem(item, header, content) {
		if (this.animatingItems.has(item)) return;
		this.animatingItems.add(item);

		// Handle toggle behavior for top-level accordion
		if (
			this.toggle &&
			!item.closest('.wp-block-proactive-accordion-item__content')
		) {
			this.items.forEach((otherItem) => {
				if (otherItem !== item) {
					const otherHeader = otherItem.querySelector(
						'.wp-block-proactive-accordion-item__header'
					);
					const otherContent = otherItem.querySelector(
						'.wp-block-proactive-accordion-item__content'
					);
					this.closeItem(otherItem, otherHeader, otherContent);
				}
			});
		}

		// Open current item
		const style = window.getComputedStyle(content);
		const duration = parseFloat(style.transitionDuration) * 1000;

		content.removeAttribute('hidden');
		item.setAttribute('aria-expanded', 'true');

		setTimeout(() => {
			content.classList.add('active');
			this.animatingItems.delete(item);
		}, duration / 2);
	}

	closeItem(item, header, content) {
		if (this.animatingItems.has(item)) return;
		this.animatingItems.add(item);

		const style = window.getComputedStyle(content);
		const duration = parseFloat(style.transitionDuration) * 1000;

		item.setAttribute('aria-expanded', 'false');
		content.classList.remove('active');

		setTimeout(() => {
			content.setAttribute('hidden', '');
			this.animatingItems.delete(item);
		}, duration);
	}

	handleKeyboardNavigation(event) {
		const target = event.target;
		const header = target.closest('.wp-block-proactive-accordion-item__header');

		if (!header) return;

		const item = header.closest('.wp-block-proactive-accordion-item');
		const container = this.findNavigationContainer(item);
		const items = Array.from(
			container.querySelectorAll('.wp-block-proactive-accordion-item')
		);
		const itemIndex = items.indexOf(item);

		switch (event.key) {
			case 'ArrowDown':
				if (itemIndex < items.length - 1) {
					event.preventDefault();
					items[itemIndex + 1]
						.querySelector('.wp-block-proactive-accordion-item__header')
						.focus();
				}
				break;

			case 'ArrowUp':
				if (itemIndex > 0) {
					event.preventDefault();
					items[itemIndex - 1]
						.querySelector('.wp-block-proactive-accordion-item__header')
						.focus();
				}
				break;

			case 'Enter':
				event.preventDefault();
				this.toggleItem(item);
				break;
		}
	}

	// Helper method to find the correct navigation container (top-level or nested)
	findNavigationContainer(item) {
		const parentContent = item.closest(
			'.wp-block-proactive-accordion-item__content'
		);
		return parentContent
			? parentContent.closest('.wp-block-proactive-accordion') || parentContent
			: this.accordion;
	}

	initializeFirstState() {
		// Check for anchor link
		const hash = window.location.hash;
		const anchorItem = hash ? document.querySelector(hash) : null;

		// Determine which item to open initially
		let itemToOpen = null;
		if (anchorItem && anchorItem.closest('.wp-block-proactive-accordion')) {
			itemToOpen = anchorItem;
		} else if (this.accordion.dataset.first === 'true') {
			itemToOpen = this.items[0];
		}

		// Open the selected item
		if (itemToOpen) {
			const header = itemToOpen.querySelector(
				'.wp-block-proactive-accordion-item__header'
			);
			const content = itemToOpen.querySelector(
				'.wp-block-proactive-accordion-item__content'
			);

			if (header && content) {
				this.openItem(itemToOpen, header, content);
			}
		}
	}
}

// Initialize all top-level accordions on the page
document.addEventListener('DOMContentLoaded', () => {
	const topLevelAccordions = document.querySelectorAll(
		'.wp-block-proactive-accordion:not(.wp-block-proactive-accordion-item .wp-block-proactive-accordion)'
	);
	topLevelAccordions.forEach((accordion) => new Accordion(accordion));

	// Optional: Handle window resize for content height
	window.addEventListener('resize', () => {
		topLevelAccordions.forEach((accordion) => {
			const openItems = accordion.querySelectorAll('[aria-expanded="true"]');
			openItems.forEach((item) => {
				const content = item.querySelector(
					'.wp-block-proactive-accordion-item__content'
				);
				const inner = content.querySelector(
					'.wp-block-proactive-accordion-item__content-inner'
				);
				content.style.height = `${inner.clientHeight}px`;
			});
		});
	});
});
